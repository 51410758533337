import axios from "@/plugins/axios";

// initial state
const initialData = () => ({
  loadingSidebar: true,
  glossaryMiu: [],
  clickedSoftSkills: [],
  softSkillIdToLoad: "",
});

const getters = {
  getSoftSkillsLoadingSidebar: (state) => state.loadingSidebar,
  getSoftSkillGlossaryMiu: (state) => state.glossaryMiu.filter((sk) => sk.glossary !== null),
  getSoftSkillIdToLoad: (state) => state.softSkillIdToLoad,
  getClickedSoftSkill: (state) =>
    state.clickedSoftSkills.find((item) => item.id === state.softSkillIdToLoad),
};

const actions = {
  async loadSoftSkillGlossaryMiu({ commit }, elementSoftSkill) {
    commit("setLoadingSidebar", true);

    const softSkills = elementSoftSkill.map((sk) => sk.id).toString();
    const res = await axios.get(`/api/rest/softskills?ids=${softSkills}`);

    commit("setSoftSkillGlossaryMiu", []);

    const softSkillGlossaryMiu = [];
    res.data.softskills.forEach((softSkill) => {
      softSkillGlossaryMiu.push({
        id: Number(softSkill.id),
        glossary: softSkill.glossaries
          ? {
              name: softSkill.glossaries[0].external_name,
              description: softSkill.glossaries[0].external_description,
            }
          : null,
        icon: { alternativeText: softSkill.icon.alternativeText, url: softSkill.icon.url },
      });
    });
    commit("setSoftSkillGlossaryMiu", softSkillGlossaryMiu);

    commit("setLoadingSidebar", false);
  },

  async loadSoftSkill({ commit, state }) {
    const res = await axios.get(`/api/rest/softskills?ids=${state.softSkillIdToLoad}`);
    commit("setSoftSkill", res.data);
  },
};

const mutations = {
  setLoadingSidebar(state, data) {
    state.loadingSidebar = data;
  },
  setSoftSkillGlossaryMiu(state, data) {
    state.glossaryMiu = data;
  },
  setSoftSkill(state, data) {
    state.clickedSoftSkills = [...state.clickedSoftSkills, ...data.softskills];
  },
  setSoftSkillIdToLoad(state, data) {
    state.softSkillIdToLoad = data;
  },
};

export default {
  namespaced: true,
  getters,
  state: initialData,
  actions,
  mutations,
};
