<template>
  <div class="d-flex">
    <img
      src="/images/logo_lifeed.png"
      class="brand-image header-height"
      @click="redirectToHome"
      alt="lifeed"
    />
  </div>
</template>

<script>
export default {
  name: "MenuLogos",
  methods: {
    redirectToHome() {
      this.$router.push({ name: "servicedashboard" }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-image {
  cursor: pointer;
  margin: 0;
}

.menu-container {
  width: 200px;
}

.company-logo {
  height: 45px;
}
</style>
