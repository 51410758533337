import Vue from "vue";
import mixpanel from "mixpanel-browser";

let initialised = false;

const initMixpanel = (userId, registerData) => {
  if (initialised) return;

  mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
    debug: process.env.NODE_ENV !== "production",
  });
  mixpanel.identify(userId);
  mixpanel.register({
    environment: process.env.VUE_APP_ENV || "local",
    ...registerData,
  });

  initialised = true;
};

const track = (...args) => {
  if (!initialised) return;

  mixpanel.track(...args);
};

Vue.prototype.$mixpanel = { track };

export { initMixpanel, mixpanel };
