<template>
  <div v-if="source" v-html="content" class="markdown"></div>
</template>
<script>
import { marked } from "marked";

export default {
  name: "Markdown",
  props: {
    source: String,
  },
  computed: {
    content() {
      return marked(this.source, { breaks: true });
    },
  },
};
</script>
