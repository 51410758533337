/* eslint-disable */
import axios from "@/plugins/axios";

const emptyExercise = {
  id: null,
  roles: [],
  selectedCharacteristics: [],
};

// initial state
const state = {
  currentStep: 0,
  currentExercise: emptyExercise,
  characteristicsList: [],
  oldExercises: [],
  exerciseFinished: false,
  currentResult: {},
  tourId: "",
  loading: true,
  trackingData: null,
};

const getters = {
  getCurrentExercise(state) {
    return state.currentExercise;
  },
  getOldExercises(state) {
    return state.oldExercises;
  },
  getCurrentStep(state) {
    return state.currentStep;
  },
  isExerciseFinished(state) {
    return state.exerciseFinished;
  },
  isPageExercise(state) {
    return state.currentExercise.isPage;
  },
  getSavedRoles(state) {
    return state.currentExercise.roles;
  },
  getSelectedCharacteristics(state) {
    return state.currentExercise.selectedCharacteristics;
  },
  getCharacteristicsList(state) {
    return state.characteristicsList;
  },
  getResult(state) {
    return state.currentResult;
  },
  getTour(state) {
    return state.tourId;
  },
  getLoading(state) {
    return state.loading;
  },
  getTrackingData(state) {
    return state.trackingData;
  },
};

const actions = {
  async fetchLastExercise({ commit }) {
    commit("setLoading", true);
    try {
      const res = await axios.get("/api/rest/multime/exercise/check");
      if (res.data.exercise !== null) {
        const exercise = {
          ...emptyExercise,
          id: res.data.exercise.head.id,
          roles: res.data.exercise.roles.map((r) => r.role),
          isDone: res.data.exercise.head.is_done,
        };
        commit("setCurrentExercise", exercise);

        if (res.data.exercise.roles.length > 0) {
          commit("initCharacteristics", res.data.exercise.roles);
          commit("changeStep", 2);
        }
      }
      commit("setCharacteristicsList", res.data.traits);
      commit("setLoading", false);
    } catch (err) {
      console.log(err.response.data);
      commit("setLoading", false);
    }
  },

  async fetchExercises({ commit }) {
    commit("setLoading", true);
    try {
      const res = await axios.get("/api/rest/multime");
      if (res.data.currentExercise !== null) {
        const exercise = {
          ...emptyExercise,
          id: res.data.currentExercise.head.id,
          roles: res.data.currentExercise.roles.map((r) => r.role),
        };
        commit("setCurrentExercise", exercise);

        if (res.data.currentExercise.roles.length > 0) {
          commit("initCharacteristics", res.data.currentExercise.roles);
          commit("changeStep", 1);
        }
      }
      commit("setTour", res.data.tour);
      commit("setCharacteristicsList", res.data.traits);
      if (res.data.oldResults.length > 0) {
        commit("setOldExercises", res.data.oldResults);
      }
      commit("setLoading", false);
    } catch (err) {
      console.log(err.response.data);
      commit("setLoading", false);
    }
  },
  setPageExercise({ commit }, exercise) {
    const newExercise = {
      ...emptyExercise,
      id: exercise.head.id,
      roles: exercise.roles.map((r) => r.role),
      isPage: true,
      date: exercise.head.date,
      isDone: false,
    };
    commit("setCurrentExercise", newExercise);

    if (exercise.roles.length > 0) {
      commit("initCharacteristics", exercise.roles);
      commit("changeStep", 1);
    }
    commit("setCharacteristicsList", exercise.traits);
    commit("setExerciseState", exercise.completion);
  },
  async createExercise({ commit }, payload) {
    try {
      let data;
      if (payload.type === "stream_page") {
        data = payload;
      } else {
        data = { page_id: payload.pageId };
      }
      const res = await axios.post("/api/rest/multime/create", data);
      const newExercise = {
        ...emptyExercise,
        id: res.data.exerciseId,
        isPage: payload.type !== "standalone",
        date: res.data.exerciseDate,
        isDone: false,
      };
      commit("setCurrentExercise", newExercise);
    } catch (err) {
      console.log(err.response.data);
    }
  },
  async getExercise({ commit }, pageId) {
    try {
      await axios.post("/api/rest/multime/show/", { page_id: pageId });
    } catch (err) {
      console.log(err.response.data);
    }
  },
  async postRoles({ state, commit }, roles) {
    try {
      const data = {
        exercise_id: state.currentExercise.id,
        roles: roles,
      };
      const res = await axios.post("/api/rest/multime/exercise/save", data);
      commit("saveRoles", roles);
      commit("initCharacteristics", res.data.roles);
      commit("changeStep", 1);
      return true;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async postCharacteristics({ state, commit, dispatch }) {
    try {
      const data = {
        exercise_id: state.currentExercise.id,
        details: state.currentExercise.selectedCharacteristics,
      };
      await axios.post("/api/rest/multime/exercise/traits/save", data);
      commit("setExerciseState", true);
      commit("setExerciseDone", true);

      if (!state.currentExercise.isPage) {
        commit("changeStep", 4);
      }
    } catch (err) {
      console.log(err.response.data);
    }
  },
  async fetchResult({ commit }, id) {
    try {
      commit("setLoading", true);
      const res = await axios.get(`/api/rest/multime/show/${id}`);
      commit("setLoading", false);
      commit("setResult", res.data);
    } catch (err) {
      console.log(err.response.data);
      commit("setLoading", false);
    }
  },
  async deleteExercise({ commit }, id) {
    try {
      commit("setLoading", true);
      const res = await axios.delete(`/api/rest/multime/exercise/delete/${id}`);
      commit("setLoading", false);
      commit("clearExercise");
      commit("setResult", res.data);
    } catch (err) {
      console.log(err.response.data);
      commit("setLoading", false);
    }
  },
};

const mutations = {
  setExerciseDone(state, done) {
    state.currentExercise.isDone = done;
  },
  setCurrentExercise(state, exercise) {
    state.currentExercise = exercise;
  },
  setOldExercises(state, exercises) {
    state.oldExercises = exercises;
  },
  setCharacteristicsList(state, characteristics) {
    state.characteristicsList = characteristics;
  },
  saveRoles(state, roles) {
    state.currentExercise.roles = roles;
  },
  initCharacteristics(state, roles) {
    state.currentExercise.selectedCharacteristics = [];
    roles.map((role) => {
      state.currentExercise.selectedCharacteristics.push({
        roleId: role.id,
        characteristics: [],
      });
    });
  },
  toggleCharacteristic(state, payload) {
    const roleIndex = payload.roleIndex;
    const cId = payload.cId;
    let roleCharacteristics = state.currentExercise.selectedCharacteristics[roleIndex];
    const foundIndex = roleCharacteristics.characteristics.findIndex((c) => c === cId);
    if (foundIndex === -1) {
      roleCharacteristics.characteristics.push(cId);
    } else {
      state.currentExercise.selectedCharacteristics[roleIndex].characteristics =
        state.currentExercise.selectedCharacteristics[roleIndex].characteristics.filter(
          (c) => c !== cId
        );
    }
  },
  changeStep(state, step) {
    state.currentStep = step;
  },
  setExerciseState(state, exState) {
    state.exerciseFinished = exState;
  },
  clearExercise(state) {
    state.currentExercise = emptyExercise;
    state.exerciseFinished = false;
    state.currentStep = 0;
  },
  setResult(state, result) {
    state.currentResult = result;
  },
  setTour(state, tourId) {
    state.tourId = tourId;
  },
  setLoading(state, loadingState) {
    state.loading = loadingState;
  },
  setTrackingData(state, data) {
    state.trackingData = data;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
