const mixin = {
  data() {
    return {
      screenWidth: 0,
    };
  },
  computed: {
    isMobile() {
      return !(this.screenWidth > 768);
    },
    isTablet() {
      return !(this.screenWidth > 1024);
    },
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};

export default mixin;
