import Vue from "vue";
import "./plugins/axios";
import "./plugins/bootstrap-vue";
import { initIntercom } from "./plugins/intercom";
import "./plugins/vee-validate";
import "./plugins/visibility-observer";
import "./plugins/mixpanel";
import "./plugins/cookiebot";
import "./plugins/vue-debounce";
import "./plugins/vue-social-sharing";
import "./plugins/add-to-calendar";
import { initSentry } from "./plugins/sentry";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

Vue.config.productionTip = false;

const handleError = (status) => {
  switch (status) {
    case 404:
      router.push({ name: "404" });
      break;
    case 500:
      router.push({ name: "exceptionpage" });
      break;
    default:
      console.error(status);
  }
};

window.addEventListener("servererror", (event) => {
  handleError(event.detail);
});

if (process.env.NODE_ENV !== "test") {
  // Intercom initalisation
  initIntercom();
}

if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line global-require
  const { worker } = require("./mocks/browser");
  worker.start();
} else {
  // Sentry initialisation
  initSentry();
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
