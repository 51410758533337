/* eslint-disable */
import axios from "@/plugins/axios";

const getNextElement = (elements, id, type) => {
  //Find the index of the current element
  const index = elements.findIndex((el) => el.type.toLowerCase() === type && Number(el.id) === id);

  // Element does not exist in the ale mius
  if (index === -1) return null;

  // Element is the last one of the ale
  if (index === elements.length - 1) return { final: true };

  // Get the next element
  const nextElement = elements[index + 1];

  return nextElement;
};

const emptyStream = {
  progress: {
    unit: { completed: 0, count: 0 },
    mission: { completed: 0, count: 0 },
    softskill: [],
  },
  ales: [],
  id: null,
};

// initial state
const state = () => ({
  loading: true,
  currentStream: emptyStream,
  currentElement: {},
  currentAle: {
    id: null,
  },
  elementSoftSkill: null,
  currentElementType: null,
  loadingPage: true,
  currentPage: 1,
  blocksPerPage: null,
  miuTestData: {
    isTypeA: false,
    isTested: false,
  },
  streamLogId: null,
  sidebarVisible: false,
  currentElementIndex: null,
  sessionsWithoutWebinars: [],
  sessionsWebinars: [],
});

const getters = {
  getSidebarVisible: (state) => {
    return state.sidebarVisible;
  },
  getCurrentStreamData: (state) => {
    return state.currentStream;
  },
  getElementTitle: (state) => {
    return state.currentElement.title;
  },
  getAutoReflectionStarted: (state) => {
    return state.currentStream.ales[0]
      ? state.currentStream.ales[0].groups[0].elements[0].started
      : false;
  },
  getCurrentStreamId: (state) => {
    return state.currentStream.id;
  },
  getCurrentElementLocale: (state) => {
    return state.currentElement.locale.locale;
  },
  getStreamLoading: (state) => state.loading,
  getStreamInfo: (state) => {
    return {
      title: state.currentElement.title,
      subtitle: state.currentElement.subtitle,
      id: state.currentElement.id,
      icon: state.currentElement.miu_type ? state.currentElement.miu_type.icon.url : "",
    };
  },
  getMiuBlocks: (state) => {
    return state.currentElement.blocks ? state.currentElement.blocks : [];
  },
  getPageBlocks: (state) => (page) => {
    const firstPageBlock = (page - 1) * state.blocksPerPage;
    const lastPageBlock = firstPageBlock + state.blocksPerPage;
    return state.currentElement.blocks.slice(firstPageBlock, lastPageBlock);
  },
  getPromptAnswers: (state) => (promptId) => {
    if (!state.currentElement.answers || !state.currentElement.answers.hasOwnProperty(promptId)) {
      return null;
    }

    return state.currentElement.answers[promptId].filter((data) => data.key === "answer");
  },
  getPromptMood: (state) => (promptId) => {
    if (!state.currentElement.answers || !state.currentElement.answers.hasOwnProperty(promptId)) {
      return null;
    }

    return state.currentElement.answers[promptId].filter((data) => data.key === "mood_id");
  },
  getLoadingPageState: (state) => state.loadingPage,
  getMissionStatus: (state) => state.currentElement.status,
  getCurrentPage: (state) => state.currentPage,
  getMiuType: (state) => (state.currentElement ? state.currentElement.miu_type : null),
  getCurrentElementType: (state) => state.currentElementType,
  getCurrentElementId: (state) => (state.currentElement ? state.currentElement.id : null),
  getCurrentElementTitle: (state) => state.currentElement.title,
  getCurrentAleId: (state) => (state.currentAle ? state.currentAle.id : null),
  getElementSoftSkill: (state) => state.elementSoftSkill,
  getPagesLength: (state) =>
    state.currentElement.blocks
      ? Math.ceil(state.currentElement.blocks.length / state.blocksPerPage)
      : 1,
  getIsStreamEmpty: (state) => (state.currentStream.ales ? false : true),
  getNextElement: (state) => {
    if (
      !state.currentAle.id ||
      !state.currentElement.id ||
      !state.currentElementType ||
      !state.sessionsWithoutWebinars
    )
      return null;

    const currentAle = state.sessionsWithoutWebinars.find(
      (session) => session.id === Number(state.currentAle.id)
    );
    return getNextElement(currentAle.elements, state.currentElement.id, state.currentElementType);
  },
  getStreamLogId: (state) => state.streamLogId,
  getMiuTestData: (state) => state.miuTestData,
  getCurrentElementIndex: (state) => state.currentElementIndex,
  getSessionsWithoutWebinars: (state) => state.sessionsWithoutWebinars,
  getSessionsWebinars: (state) => state.sessionsWebinars,
};

const actions = {
  async loadStream({ commit, state }, id) {
    commit("setStreamLoading", true);
    commit("resetStream");
    try {
      const res = await axios.get(`/api/rest/streams/${id}/dashboard/`);
      commit("setStream", res.data);
      commit("setStreamLoading", false);
    } catch (err) {
      console.error(err);
    }
  },
  async loadMiu({ commit }, id) {
    commit("setLoading", true);
    let url = `/api/rest/streams/miu/${id}`;
    const res = await axios.get(url);
    commit("setElementData", res.data);
    commit("setCurrentElementType", "miu");
    commit("setLoading", false);
  },
  async loadElement({ commit, state }, payload) {
    try {
      commit("setLoading", true);
      let url = `/api/rest/streams/${payload.id}/${payload.localisation}/${payload.aleId}/${payload.elementType}s/${payload.elementId}`;
      if (payload.elementState) {
        url += `/${payload.elementState}`;
      }
      const res = await axios.get(url);
      commit("setStreamLogId", res.data.streamLogId);
      commit("setCurrentElementType", payload.elementType);
      if (res.data.softskills) {
        commit("setElementSoftSkill", res.data.softskills);
      }
      if (res.data.testFeatures) {
        commit("setMiuTestData", res.data.testFeatures.miunavigation);
      }
      commit("setElementData", res.data);

      commit("moodsAndReactions/setInitialReactions", res.data.reactions, { root: true });
    } catch (err) {
      console.error(err);
    }
  },
  async submitActivity({ commit, state }, data) {
    try {
      return await axios.post("/api/rest/streams/activity", data);
    } catch (err) {
      console.error(err);
    }
  },
  async submitElement({ commit, state }, data) {
    try {
      return await axios.post("/api/rest/element/complete", data);
    } catch (err) {
      console.error(err);
    }
  },
  async deleteActivity({ commit, state }, data) {
    try {
      return await axios.delete(`/api/rest/streams/block/${data.main.objectId}`);
    } catch (err) {
      console.error(err);
    }
  },
  logElementActivity({ state }) {
    try {
      if (state.streamLogId) {
        axios.post("/api/rest/streams/streamLogs", { streamLogId: state.streamLogId });
      }
    } catch (err) {
      console.error(err);
    }
  },
  async fetchReflections({ commit }) {
    try {
      const res = await axios.get("/api/rest/autoReflections");
      commit("setStream", res.data);
    } catch (err) {
      console.error(err);
    }
  },
};

const mutations = {
  setSidebarVisible(state, data) {
    state.sidebarVisible = data;
  },
  setStreamLoading(state, data) {
    state.loading = data;
  },
  setStream(state, data) {
    state.currentStream = data;

    const sessions =
      data.ales &&
      data.ales.map((ale) => {
        return {
          ...ale,
          elements: ale.groups.map((group) => group.elements).flat(),
        };
      });

    state.sessionsWithoutWebinars =
      sessions &&
      sessions.map((session) => {
        return {
          ...session,
          elements: session.elements.filter((element) => element.type !== "Webinar").flat(),
        };
      });

    const webinars = new Array();
    sessions &&
      sessions.forEach((session) => {
        let webinarElement = session.elements.filter((element) => element.type === "Webinar");
        if (webinarElement.length) {
          webinars.push(...webinarElement);
        }
      });
    state.sessionsWebinars = webinars;
  },
  resetStream(state) {
    state.currentStream = { ...emptyStream };
  },
  setElementData(state, data) {
    state.currentElement = data;
    const blocks = state.currentElement.blocks;
    state.currentElement.blocks = blocks.filter(
      (block) => block.__component !== "atoms.not-break-block"
    );
    state.loadingPage = false;
  },
  setBlocksPerPage(state, data) {
    state.blocksPerPage = data;
  },
  setCurrentPage(state, page) {
    state.currentPage = page;
  },
  setLoading(state, data) {
    state.loadingPage = data;
  },
  setPromptAnswers(state, data) {
    let answers = { ...state.currentElement.answers };
    answers[data.main.objectId] = data.details;
    state.currentElement.answers = answers;
  },
  setCurrentElementType(state, type) {
    state.currentElementType = type;
  },
  setElementSoftSkill(state, data) {
    state.elementSoftSkill = data;
  },
  setMiuTestData(state, componentType) {
    state.miuTestData.isTested = componentType !== null;
    state.miuTestData.isTypeA = componentType === "A";
  },
  setCurrentAleId(state, id) {
    state.currentAle.id = id;
  },
  setStreamLogId(state, id) {
    state.streamLogId = id;
  },
  setProgressUpdate(state) {
    state.currentStream.progress.miu.completed++;
  },

  setMiuCompleted(state, id) {
    state.currentStream.ales[0].groups.map((group) => {
      group.elements.map((element) => {
        if (element.id === id) {
          element.completed = true;
        }
      });
    });
  },
  setCurrentElementIndex(state, index) {
    state.currentElementIndex = index;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
