import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import multime from "./modules/multime";
import profile from "./modules/profile";
import streams from "./modules/streams";
import journal from "./modules/journal";
import stepper from "./modules/stepper";
import moodsAndReactions from "./modules/moodsAndReactions";
import softSkills from "./modules/softSkills";
import sideMenu from "./modules/sideMenu";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {
    user,
    profile,
    multime,
    streams,
    journal,
    stepper,
    softSkills,
    moodsAndReactions,
    sideMenu,
  },
  strict: debug,
});

export default store;
