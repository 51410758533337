const mixin = {
  data() {
    return {
      scroll: false,
      scrollActivated: false,
      scrollEnd: false,
      resizeObserver: new ResizeObserver(() => {
        this.isScrolling();
        this.checkScroll();
      }),
    };
  },
  methods: {
    checkScroll() {
      this.scrollActivated = window.innerHeight < this.contentContainer.clientHeight;
    },
    listenScrolling() {
      window.addEventListener("scroll", this.isScrolling);
    },
    stopListenScrolling() {
      window.removeEventListener("scroll", this.isScrolling);
    },
    observeHeight() {
      this.resizeObserver.observe(this.contentContainer);
    },
    unobserveHeight() {
      this.resizeObserver.unobserve(this.contentContainer);
    },
    isScrolling() {
      this.scroll = window.scrollY !== 0;

      this.scrollEnd =
        window.innerHeight + window.pageYOffset >= this.contentContainer.clientHeight &&
        window.pageYOffset !== 0;
    },
  },
  computed: {
    contentContainer() {
      return document.querySelectorAll(".content-container")[1];
    },
    scrollActivatedNotEnd() {
      return this.scrollActivated && !this.scrollEnd;
    },
  },
};

export default mixin;
