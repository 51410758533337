import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return { x: 0, y: 0 };
  },
});

let authChecked = false;

router.beforeEach(async (to, from, next) => {
  if ((to.name === "Register" || to.name === "OldRegister") && to.params.company) {
    window.location.replace(`${process.env.VUE_APP_ENTERPRISE_URL}/register/${to.params.company}`);
    return;
  }

  if (!authChecked) {
    await store.dispatch("user/checkAuth");
    authChecked = true;

    const introductionStatus = store.getters["stepper/getStatus"];
    const hasIntroduction = store.getters["stepper/getHasIntroduction"];
    const introId = store.getters["stepper/getIntroductionId"];
    if (hasIntroduction && introductionStatus === null && to.name === "servicedashboard") {
      next(`/stepper/${introId}`);
    }
  }

  next();
});

export default router;
