<template>
  <footer class="footer mt-auto py-3 text-center w-100">
    <div class="container-fluid p-0">
      <div class="row mx-4 px-3 justify-content-center justify-content-md-start">
        <div class="float-sm-left">
          <b-img
            src="/images/lbv_logo_black.svg"
            alt="Life Based Value"
            class="pl-2 footer-image"
          />
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-12">
          <hr class="footer-divider" />
        </div>
        <div class="col-12 pb-3">
          <span class="text-muted">
            &copy; {{ date.getFullYear() }} Life Based Value s.r.l. - Via San Gregorio 12, 20124
            Milan - P.I. 09236350964
          </span>

          <ul class="d-flex flex-row flex-wrap justify-content-center navbar-nav">
            <li v-for="(item, index) in footer" :key="index">
              <a :href="item.href" target="_blank" class="text-muted" tabindex="0">{{
                item.label
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  name: "SiteFooter",
  data() {
    return {
      date: new Date(),
    };
  },
  mounted() {
    store.dispatch("user/getFooter");
  },
  computed: {
    ...mapGetters({
      support: "user/getSupportDetails",
      footer: "user/getFooter",
    }),
  },
};
</script>

<style scoped lang="scss">
.footer-divider {
  border-color: rgba($color-dark-600, 0.2);
}

.footer-image {
  min-width: 164px;
  max-width: 164px;
}

li {
  list-style: none;
  &:not(:last-child)::after {
    content: "-";
    margin: 0 4px;
  }
}
</style>
