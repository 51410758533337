import axios from "@/plugins/axios";

const data = () => ({
  navigation: null,
});

const getters = {
  getNavigation: (state) => state.navigation,
};

const actions = {
  async loadNavigation({ commit }) {
    try {
      const res = await axios.get(`/api/sideMenu`);

      commit("setNavigation", res.data);
    } catch (err) {
      console.log(err);
    }
  },
};

const mutations = {
  setNavigation(state, navigation) {
    state.navigation = navigation;
  },
};

export default {
  namespaced: true,
  getters,
  state: data,
  actions,
  mutations,
};
