/* eslint-disable */
import axios from "@/plugins/axios";

// initial state
const state = () => ({
  loadingProgramsSkills: true,
  programsSkills: [],
  loadingServices: true,
  loadingReflections: true,
  loadingMoreReflections: false,
  reflections: [],
  reflectionsSearchPars: {
    serviceId: 0,
    from: "",
    to: "",
    order: "desc",
    currentPage: 1,
    lastPage: 1,
    services: [],
    serviceType: null,
  },
  streamsGraphData: {
    name: "",
    image: "",
    children: [],
  },
});

const getters = {
  getProgramsSkills: (state) => {
    return state.programsSkills;
  },
  getReflections: (state) => {
    return state.reflections;
  },
  getProgramsSkillsLoading: (state) => {
    return state.loadingProgramsSkills;
  },
  getServicesLoading: (state) => {
    return state.loadingServices;
  },
  getReflectionsLoading: (state) => {
    return state.loadingReflections;
  },
  getReflectionsLoadingMore: (state) => {
    return state.loadingMoreReflections;
  },
  getReflectionsPars: (state) => {
    return state.reflectionsSearchPars;
  },
  getStreamGraphData: (state) => {
    return state.streamsGraphData;
  },
};

const actions = {
  async loadProgramsSkills({ commit }) {
    try {
      let url = `/api/rest/journal/program/softskills`;
      const res = await axios.get(url);

      if (res.data) commit("setProgramsSkills", res.data);

      commit("setProgramsSkillsLoading", false);
    } catch (err) {
      console.error(err);
    }
  },
  async loadReflections({ commit, state }, payload) {
    try {
      //loading not activated with show-more
      if (payload.page <= state.reflectionsSearchPars.currentPage)
        commit("setReflectionsLoading", true);

      let url = `/api/rest/journal/reflections?serviceType=` + payload.serviceType;
      let params = {
        page: payload.page,
        order: payload.order,
        from: payload.from,
        to: payload.to,
        serviceId: payload.serviceId,
      };
      const res = await axios.get(url, { params: params });

      if (res.data) {
        let reflectionsNew = [];
        if (res.data.reflections && res.data.reflections.length > 0) {
          if (state.reflections !== undefined)
            reflectionsNew = state.reflections.concat(res.data.reflections);
          else reflectionsNew = res.data;
        }
        commit("setReflections", reflectionsNew);

        commit("setReflectionsSearchPars", {
          serviceId: payload.serviceId,
          from: payload.from,
          to: payload.to,
          order: payload.order,
          currentPage: payload.page,
          serviceType: payload.serviceType,
        });

        //calc last page value
        let paramsNextStep = {
          page: payload.page + 1,
          order: payload.order,
          from: payload.from,
          to: payload.to,
          serviceId: payload.serviceId,
        };
        const resNextStep = await axios.get(url, { params: paramsNextStep });
        if (resNextStep.data.reflections && resNextStep.data.reflections.length >= 1) {
          commit("setReflectionsSearchPars", { lastPage: payload.page + 1 });
        }
      }

      commit("setReflectionsLoading", false);
    } catch (err) {
      console.error(err);
    }
  },
  async loadStreamsGraph({ commit }) {
    try {
      const response = await axios.get("/api/rest/journal/stream/graph");
      commit("setStreamsGraphData", response.data);
    } catch (err) {
      console.error(err);
    }
  },
  async loadReflectionsServices({ commit }) {
    try {
      let url = `/api/rest/journal/services`;
      const res = await axios.get(url);

      if (res.data.allServices) {
        let services = [];

        for (let servicesType of res.data.allServices) {
          for (let pKey in servicesType) {
            services.push({
              value: servicesType[pKey].id,
              text: servicesType[pKey].name,
              type: servicesType[pKey].serviceType,
            });
          }
        }
        commit("setReflectionsServices", services);
      }

      commit("setServicesLoading", false);
    } catch (err) {
      console.error(err);
    }
  },
};

const mutations = {
  setProgramsSkills(state, data) {
    state.programsSkills = data;
  },
  setReflections(state, data) {
    state.reflections = data;
  },
  setProgramsSkillsLoading(state, data) {
    state.loadingProgramsSkills = data;
  },
  setServicesLoading(state, data) {
    state.loadingServices = data;
  },
  setReflectionsLoading(state, data) {
    state.loadingReflections = data;
  },
  setReflectionsLoadingMore(state, data) {
    state.loadingMoreReflections = data;
  },
  setReflectionsSearchPars(state, data) {
    if (data.serviceId !== undefined) state.reflectionsSearchPars.serviceId = data.serviceId;

    if (data.from !== undefined) state.reflectionsSearchPars.from = data.from;

    if (data.to !== undefined) state.reflectionsSearchPars.to = data.to;

    if (data.order !== undefined) state.reflectionsSearchPars.order = data.order;

    if (data.currentPage !== undefined) state.reflectionsSearchPars.currentPage = data.currentPage;

    if (data.lastPage !== undefined) state.reflectionsSearchPars.lastPage = data.lastPage;

    if (data.serviceType !== undefined) state.reflectionsSearchPars.serviceType = data.serviceType;
  },
  setReflectionsPrograms(state, data) {
    state.reflectionsSearchPars.programs = data;
  },
  setStreamsGraphData(state, data) {
    state.streamsGraphData.name = data.user.name;
    state.streamsGraphData.image = data.user.image;
    state.streamsGraphData.children = data.children;
  },
  setReflectionsServices(state, data) {
    state.reflectionsSearchPars.services = data;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
