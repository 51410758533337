import { localeChanged } from "vee-validate";
import { initMixpanel, mixpanel } from "@/plugins/mixpanel";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { boot, shutdown } from "@/plugins/intercom";

const emptyState = {
  loading: false,
  activeServices: false,
  locale: "it",
  firstName: "",
  lastName: "",
  displayName: "",
  email: "",
  id: null,
  privacyLink: "",
  cookieLink: "",
  termsLink: "",
  supportMail: "",
  intendedUrl: "",
  companyLocale: [],
  emailLabel: {},
  companyLogo: "",
  companyName: "",
  profile: {},
  avatar: "",
  gender: "",
  notificationsCount: 0,
  menu: null,
  footer: null,
  hasClosedCommunity: false,
  mixpanelData: null,
  statisticsPermitted: false,
};

// initial state
const data = () => emptyState;

const actions = {
  async checkAuth({ commit, dispatch }) {
    commit("setLoading", true);

    try {
      await dispatch("fetchCookie");

      const res = await axios.get("/api/rest/check");
      commit("setSupportDetails", res.data);
      if (!res.data.user) {
        commit("setLoading", false);
        return Promise.reject();
      }
      commit("setCompanyLogo", res.data.user.company.logo);
      dispatch("switchLanguage", res.data.user.locale);
      commit("setUserData", res.data);

      if (res.data.user.company.steppers)
        dispatch("stepper/setUserSteppers", res.data.user.company.steppers, { root: true });

      if (res.data.intercom && res.data.intercom.user_id) {
        const userData = { ...res.data.user, user_id: res.data.intercom.user_id };
        // for backcompatibility we force gender to extend value male/female
        userData.gender = userData.extGender;
        delete userData.extGender;
        boot(userData);
      }

      const mixpanelData = res.data.mixpanel;

      if (mixpanelData) {
        commit("setMixpanelData", mixpanelData);
        dispatch("initTracking");
      }

      await dispatch("getMenu");

      commit("setLoading", false);

      return res;
    } catch (err) {
      commit("setLoading", false);

      if (err.response.status === 406) {
        dispatch("logout");
        return Promise.reject(Error("Error when fetching user data"));
      }
      return err.response.data;
    }
  },
  async fetchCookie() {
    try {
      const res = await axios.get("/api/rest/csrf-cookie");
      axios.defaults.headers.common["X-XSRF-TOKEN"] = res.data;
      return true;
    } catch (err) {
      return Promise.reject(err.response ? err.response.data : err);
    }
  },
  async logout() {
    shutdown();
    try {
      return await axios.get("/api/rest/logout");
    } catch (err) {
      return err.response.data;
    }
  },
  async switchLanguage({ commit }, locale) {
    commit("setLocale", locale);
    const newIsoLocale = locale === "it-IT" || locale === "it" ? "it-IT" : "en-GB";
    const newLocale = newIsoLocale.substring(0, 2);

    i18n.locale = newLocale;
    localeChanged();
  },
  async userProfileInfo({ commit }, userId) {
    try {
      const res = await axios.get(`/api/rest/profile/info/${userId}`);
      commit("setUserProfileInfo", res.data);
      return res.data;
    } catch (err) {
      return err.response.data;
    }
  },
  async userAvatar({ commit }, avatarImage) {
    commit("setUserAvatar", avatarImage);
  },
  async getMenu({ state, commit }, forceRefresh = false) {
    if (state.menu && !forceRefresh) return false;
    try {
      const res = await axios.get("/api/rest/nav", {
        headers: {
          "Cache-Control": "no-cache",
        },
      });
      commit("setMenu", res.data);
      // commit("setUserAvatar", res.data.profile.icon ? res.data.profile.icon : "");
      // commit("setNotificationsCount", res.data.notifications.notifications.count);
      return true;
    } catch (err) {
      return err;
    }
  },
  async getFooter({ state, commit }) {
    if (state.footer) return false;
    try {
      const res = await axios.get("/api/footer");
      commit("setFooter", res.data);
      return true;
    } catch (err) {
      return Promise.reject(err.response ? err.response.data : err);
    }
  },
  initTracking({ state }) {
    const { mixpanelData, statisticsPermitted } = state;

    if (!mixpanelData || !statisticsPermitted) return;

    initMixpanel(mixpanelData.user_id, mixpanelData);
    mixpanel.track("LifeedServiceAccess", { serviceName: "learn" });
  },
};

const getters = {
  getLoadingStatus(state) {
    return state.loading;
  },
  getUserFirstname(state) {
    return state.firstName;
  },
  getUserId(state) {
    return state.id;
  },
  getUserActiveServices(state) {
    return state.activeServices;
  },
  getLocale(state) {
    return state.locale;
  },
  getCompanyInfo(state) {
    return {
      emailLabel: state.emailLabel,
      locale: state.companyLocale,
      logo: state.companyLogo,
      name: state.companyName,
    };
  },
  getSupportDetails(state) {
    return {
      privacyLink: state.privacyLink,
      cookieLink: state.cookieLink,
      termsLink: state.termsLink,
      supportMail: state.supportMail,
    };
  },
  getIntendedUrl(state) {
    return state.intendedUrl;
  },
  getUserProfileInfo(state) {
    return state.profile;
  },
  getUserAvatar(state) {
    return state.avatar;
  },
  getUserData(state) {
    return {
      displayName: state.displayName,
      email: state.email,
    };
  },
  getNotificationsCount(state) {
    return state.notificationsCount;
  },
  getMenu: (state) => state.menu,
  getFooter: (state) => state.footer,
  getHasClosedCommunity: (state) => state.hasClosedCommunity,
};

const mutations = {
  setLoading(state, status) {
    state.loading = status;
  },
  setUserData(state, payload) {
    state.firstName = payload.user.first_name;
    state.lastName = payload.user.last_name;
    state.displayName = payload.user.display_name;
    state.email = payload.user.login_email;
    state.gender = payload.user.gender;
    state.id = payload.user.id;
    state.companyName = payload.user.company.name;
    state.hasClosedCommunity = payload.user.company.closedCommunity;
    state.activeServices = payload.user.active_services;
  },
  setSupportDetails(state, payload) {
    state.privacyLink = payload.docs.privacy;
    state.cookieLink = payload.docs.cookie;
    state.termsLink = payload.docs.terms;
    state.supportMail = payload.support;
  },
  setLocale(state, locale) {
    state.locale = locale;
  },
  setCompanyLogo(state, logo) {
    state.companyLogo = logo;
  },
  setIntendedUrl(state, url) {
    state.intendedUrl = url;
  },
  setUserProfileInfo(state, payload) {
    state.profile = payload;
  },
  setUserAvatar(state, payload) {
    state.avatar = payload;
  },
  setUserGender(state, payload) {
    state.gender = payload;
  },
  setNotificationsCount(state, notificationsCount) {
    state.notificationsCount = notificationsCount;
  },
  setMenu(state, menu) {
    state.menu = menu;
  },
  setFooter(state, footer) {
    state.footer = footer;
  },
  setMixpanelData(state, mixpanelData) {
    state.mixpanelData = mixpanelData;
  },
  setStatisticsPermitted(state, status) {
    state.statisticsPermitted = status;
  },
};

export default {
  namespaced: true,
  getters,
  state: data,
  actions,
  mutations,
};
