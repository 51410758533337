<template>
  <b-nav-item-dropdown right @shown="expandDropdownList" no-caret role="list">
    <template slot="button-content">
      <b-avatar :src="userAvatar" variant="primary" :size="isTablet ? 35 : 45" />
    </template>

    <b-dropdown-header id="profile-header-label">
      <div class="h6 header-text">{{ userData.displayName }}</div>
      <div class="header-text">{{ userData.email }}</div>
    </b-dropdown-header>
    <ul
      ref="dropdownList"
      class="list-unstyled m-0"
      :aria-label="`${$t('global.profileMenu.ariaLabel')}`"
      tabindex="0"
    >
      <li v-for="(menuProfile, i) in menuItem" :key="i">
        <b-dropdown-item
          class="d-flex"
          :class="setClass(menuProfile.route)"
          @click="handleLinkClick(menuProfile)"
        >
          {{ menuProfile.label }}
          <b-icon-box-arrow-right
            v-if="isLogout(menuProfile.route)"
            class="icon"
            aria-hidden="true"
          />
        </b-dropdown-item>
        <b-dropdown-divider v-if="i < menuItem.length - 1" class="px-4" />
      </li>
    </ul>

    <div class="mx-4 mt-2 mb-3" v-if="companyLogo">
      <img :src="companyLogo" class="img-fluid" alt="" />
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import mobileCheckMixin from "../mixins/mobileCheckMixin";

export default {
  name: "ProfileDropDown",
  props: {
    menuItem: Array,
    currentPath: String,
    analytics: Object,
    companyLogo: String,
  },
  methods: {
    expandDropdownList() {
      this.$emit("shown", { dropdownList: this.$refs.dropdownList });
      this.$emit("trackDropdown");
      this.$refs.dropdownList.focus();
    },
    isLogout(route) {
      return route === "";
    },
    handleLinkClick(nav) {
      let eventName = "changeRoute";

      if (nav.eventName) {
        eventName = nav.eventName;
      }

      this.$emit(eventName, { label: nav.label, id: nav.idMenu, route: nav.route });
    },
    setClass(route) {
      if (this.currentPath === route) {
        return "active";
      }
      if (this.isLogout(route)) {
        return "logout";
      }
      return "";
    },
  },
  computed: {
    ...mapGetters({
      userAvatar: "user/getUserAvatar",
      userData: "user/getUserData",
    }),
  },
  mixins: [mobileCheckMixin],
};
</script>

<style lang="scss" scoped>
.icon {
  margin-left: ($spacer / 3);
  font-size: 170% !important;
  margin-bottom: -3px;
}

.header-text {
  color: black;
}
</style>
