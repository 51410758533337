const mixin = {
  methods: {
    parseNav(nav) {
      let route = "";
      let postRoute = "";
      let query = "";
      if (!nav.object) return;

      switch (nav.object) {
        case "dashboard":
          route = "/dashboard";
          break;
        case "program":
          route = "/programs/";
          break;
        case "webinar":
          route = "/webinar";
          break;
        case "webinar_master":
          route = "/webinar/";
          postRoute = "/master";
          break;
        case "webinar_detail":
          route = "/webinar/";
          postRoute = "/detail";
          break;
        case "community":
          route = "/community";
          break;
        case "service_activation":
          route = "/activation/";
          break;
        case "wizard":
          route = "/profiling";
          break;
        case "stream":
          if (nav.object_id) {
            route = "/stream/";
          } else {
            route = "/dashboard";
          }
          break;
        case "stream_detail":
          route = "/stream/";
          postRoute = "/detail";
          break;
        case "stream_certificate":
          route = "/stream/";
          postRoute = "/certificate";
          break;
        case "company_dashboard":
          route = "/company-dashboard";
          break;
        default:
          console.log(`unknown nav type! ${nav.object}`);
      }

      // construct query string
      if (nav.query) {
        query += "?";
        const keys = Object.keys(nav.query);

        keys.forEach((key, index) => {
          query += `${key}=${nav.query[key]}`;

          if (index !== keys.length - 1) {
            query += "&";
          }
        });
      }
      // eslint-disable-next-line
      return (route += nav.object_id + postRoute + query);
    },
    parseMasterNav(nav) {
      const route = `/programs/${nav.programId}`;
      switch (nav.object) {
        // programs
        case "program":
          return `${route}`;
        case "program_finale":
          return `${route}/finale`;
        case "program_certificate":
          return `${route}/certificate`;
        case "webinar_program":
          return `/webinar/${nav.webinarId}${route}`;

        // modules
        case "module_cover":
          return `${route}/modules/${nav.moduleId}`;
        case "module_end":
          return `${route}/modules/${nav.moduleId}/completed`;

        // pages
        case "page":
          return `${route}/modules/${nav.moduleId}/pages/${nav.pageId}`;

        // missions
        case "mission_scheduling":
          return `${route}/modules/${nav.moduleId}/missions/${nav.missionId}/scheduled`;
        case "mission_end":
          return `${route}/modules/${nav.moduleId}/missions/${nav.missionId}/completed`;
        case "mission_complete":
          return `${route}/modules/${nav.moduleId}/missions/${nav.missionId}/complete`;

        default:
          console.log(`unknown nav type! ${nav.object}`);
          return null;
      }
    },
  },
  computed: {
    backButtonRoutes() {
      return ["journal-reflections, servicedashboard"];
    },
  },
};

export default mixin;
