import axios from "@/plugins/axios";

// initial state
const initialState = () => ({
  introduction: {
    id: null,
    status: null,
    ignore: false,
  },
});

const getters = {
  getHasIntroduction: (state) => state.introduction.id,
  getStatus: (state) => state.introduction.status,
  getIntroductionId: (state) => state.introduction.id,
};

const actions = {
  async registerStatus({ commit }, payload) {
    try {
      const data = {
        stepper: payload.stepper,
        status: payload.status,
      };
      await axios.post(`/api/rest/stepper/finale/${payload.stepperId}`, data);

      commit("setStatus", data);
    } catch (err) {
      return err;
    }
    return null;
  },
  setUserSteppers({ commit }, payload) {
    if (payload) {
      const data = {
        stepper: "introduction",
        id: payload.id,
      };
      commit("setId", data);

      if (payload.status) {
        const data2 = {
          stepper: "introduction",
          status: payload.status,
        };
        commit("setStatus", data2);
      }
    }
  },
};

const mutations = {
  setId(state, payload) {
    state[payload.stepper].id = payload.id;
  },
  setStatus(state, payload) {
    state[payload.stepper].status = payload.status;
  },
};

export default {
  namespaced: true,
  getters,
  state: initialState,
  actions,
  mutations,
};
