import axios from "@/plugins/axios";

// initial state
const initialState = () => ({
  moodsList: {
    moods: [],
    dropdown: [],
    notSelected: -1,
  },
  reactionList: {
    reactions: [],
    dropdown: [],
    notSelected: -1,
  },
  reactionTestData: {
    isTypeA: false,
    isTested: false,
  },
  moodTestData: {
    isTypeA: false,
    isTested: false,
  },
  reactions: null,
});

const getters = {
  getMoodsList: (state) => state.moodsList,
  getActiveMoodsList: (state) => state.moodsList.moods.filter((m) => m.active !== false),
  getMood: (state) => (id) => state.moodsList.moods.find((m) => m.id === id),
  getReactionList: (state) => state.reactionList,
  getActiveReactionList: (state) => state.reactionList.reactions.filter((r) => r.active !== false),
  getReaction: (state) => (id) => state.reactionList.reactions.find((r) => r.id === id),
  getReactionTestData: (state) => state.reactionTestData,
  getMoodTestData: (state) => state.moodTestData,
  getBlockReactionsCounters: (state) => (blockId) => {
    if (!state.reactions || !state.reactions.counters || !state.reactions.counters[blockId]) {
      return null;
    }

    return state.reactions.counters[blockId];
  },
  getBlockReactionsSelected: (state) => (blockId) => {
    if (!state.reactions || !state.reactions.selected || !state.reactions.selected[blockId]) {
      return null;
    }

    return state.reactions.selected[blockId];
  },
};

const actions = {
  async loadMoodsList({ commit }) {
    try {
      const res = await axios.get("/api/rest/moods/all");
      commit("setMoodsList", res.data);
      if (res.data.testFeatures) {
        commit("setMoodTestData", res.data.testFeatures.moods);
      }
    } catch (err) {
      console.error(err);
    }
  },
  async loadReactionList({ commit }) {
    try {
      const res = await axios.get("/api/rest/reactions/all");
      commit("setReactionList", res.data);
      if (res.data.testFeatures) {
        commit("setReactionTestData", res.data.testFeatures.reactions);
      }
    } catch (err) {
      console.error(err);
    }
  },
};

const mutations = {
  setInitialReactions(state, data) {
    state.reactions = data;
  },
  setMoodsList(state, data) {
    const moodsComplete = data.moods;
    moodsComplete.unshift({
      id: state.moodsList.notSelected,
      name: data.dropdown.name,
      icon: data.dropdown.icon,
    });
    state.moodsList.moods = moodsComplete;
    state.moodsList.dropdown = data.dropdown;
  },
  setReactionList(state, data) {
    const reactionsComplete = data.reactions;
    reactionsComplete.unshift({
      id: state.reactionList.notSelected,
      name: data.dropdown.name,
      icon: data.dropdown.icon,
    });
    state.reactionList.reactions = reactionsComplete;
    state.reactionList.dropdown = data.dropdown;
  },
  setReactionTestData(state, componentType) {
    state.reactionTestData.isTested = componentType !== null;
    state.reactionTestData.isTypeA = componentType === "A";
  },
  setMoodTestData(state, componentType) {
    state.moodTestData.isTested = componentType !== null;
    state.moodTestData.isTypeA = componentType === "A";
  },
  setBlockReactions(state, data) {
    const reactions = { ...state.reactions };
    const newCounter = reactions && reactions.counters ? reactions.counters : {};
    if (data.reactionId) {
      if (newCounter[data.blockId] && newCounter[data.blockId][data.reactionId]) {
        newCounter[data.blockId][data.reactionId].total += 1;
      } else {
        if (!newCounter[data.blockId]) newCounter[data.blockId] = {};

        newCounter[data.blockId][data.reactionId] = { total: 1 };
      }
    }

    if (data.reactionSavedId) newCounter[data.blockId][data.reactionSavedId].total -= 1;

    reactions.counters = newCounter;

    if (!reactions.selected) reactions.selected = {};

    if (data.reactionId) {
      reactions.selected[data.blockId] = {
        id: data.reactionId,
        icon: state.reactionList.reactions.find((r) => r.id === data.reactionId).icon,
      };
    } else reactions.selected[data.blockId] = null;

    state.reactions = reactions;
  },
};

export default {
  namespaced: true,
  getters,
  state: initialState,
  actions,
  mutations,
};
