<template>
  <div
    id="header"
    role="menubar"
    class="header-menu p-0 container-fluid header-min-height bg-white"
    fixed="top"
    :class="{ 'shadow-sm rounded': scroll, 'd-none': isHiddenRoute }"
  >
    <template>
      <b-navbar class="p-0 justify-content-between header-height">
        <div class="d-flex">
          <b-button
            variant="link"
            class="text-primary sidebar-menu-btn px-md-3"
            tabindex="0"
            :aria-label="$t('global.sideMenu.ariaLabel')"
            v-b-toggle.sidebar-menu
          >
            <b-icon icon="list" />
          </b-button>
          <menu-logos alt="Lifeed" />
        </div>

        <div
          class="d-flex align-items-center"
          tabindex="0"
          role="menubar"
          :aria-label="$t('global.menu.ariaLabel')"
        >
          <menu-links :is-mobile="isMobile" @logout="attemptLogout" />
        </div>
      </b-navbar>
    </template>

    <menu-sidebar>
      <span v-if="isMobile" class="text-overline mb-0 mt-4 d-flex">
        {{ $t("global.learning") }}
      </span>
      <menu-links v-if="isMobile" />
      <hr v-if="isMobile" class="mx-4 mb-0" />
      <ul role="navigation" class="navbar-nav">
        <li v-for="(nav, index) in navigation" :key="index" class="mt-4 nav-item" role="menuitem">
          <b-link :href="nav.link" class="nav-link h6 py-0 my-0">
            {{ nav.label }}
          </b-link>
        </li>
      </ul>
    </menu-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import scrollMixin from "@/mixins/scrollMixin";
import MenuLogos from "../../molecules/MenuLogos.vue";
import MenuLinks from "../../molecules/MenuLinks.vue";
import MenuSidebar from "./MenuSidebar.vue";
import mobileCheckMixin from "../../mixins/mobileCheckMixin";

export default {
  name: "MenuContainer",
  components: {
    MenuLogos,
    MenuLinks,
    MenuSidebar,
  },
  data() {
    return {
      companyLogo: "",
      homeRoute: "",
    };
  },
  mounted() {
    this.listenScrolling();
    this.loadNavigation();
  },
  computed: {
    ...mapGetters({
      navigation: "sideMenu/getNavigation",
    }),
    isHiddenRoute() {
      return this.$route.path.startsWith("/programs/13/modules/");
    },
  },
  methods: {
    ...mapActions({
      getMenu: "user/getMenu",
      logout: "user/getMenu",
      loadNavigation: "sideMenu/loadNavigation",
    }),
    async attemptLogout() {
      await this.logout();
      window.location.replace(process.env.VUE_APP_ACCOUNTS_URL);
    },
  },
  beforeDestroy() {
    this.stopListenScrolling();
  },
  mixins: [mobileCheckMixin, scrollMixin],
};
</script>

<style scoped lang="scss">
.header-menu {
  position: fixed !important;
  top: 0px !important;
  transition: all 0.15s linear;
  z-index: 99;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.sidebar-menu-btn:focus:not(:focus-visible) {
  box-shadow: none;
}
.text-overline {
  padding: 0 1.5rem 0.75rem;
  color: $color-dark-800;
}
</style>
