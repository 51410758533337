/* eslint-disable */

//Set your APP_ID
var APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;

export const initIntercom = () => {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/" + APP_ID;
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
};

export const boot = (userData) => {
  window.Intercom("boot", { ...userData, app_id: APP_ID });
};

export const shutdown = () => window.Intercom("shutdown");

export const update = (userData) => {
  window.Intercom("update", { ...userData, app_id: APP_ID });
};

export const hideIntercom = () =>
  window.Intercom("update", {
    hide_default_launcher: true,
  });

export const showIntercom = () =>
  window.Intercom("update", {
    hide_default_launcher: false,
  });

export const trackEvent = (eventName) => {
  if (!eventName) return;
  window.Intercom("trackEvent", eventName);
};
