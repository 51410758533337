var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-navbar-nav',[_vm._l((_vm.menu),function(menuItem,i){return [(menuItem.type === 'notifications')?_c('b-nav-item',{key:i,staticClass:"d-flex align-items-center notification",attrs:{"role":"menuitem","link-classes":"py-0"},on:{"click":function($event){return _vm.changeRoute('notifications', menuItem.idMenu, menuItem.route)}}},[_c('b-icon-bell',{attrs:{"font-scale":"1.5","aria-label":_vm.$t('global.menu.notifications')}}),(_vm.notificationsCount > 0)?_c('b-badge',{attrs:{"pill":"","variant":"secondary"}},[_vm._v(" "+_vm._s(_vm.notificationsCount)+" ")]):_vm._e()],1):(menuItem.type === 'profile')?_c('div',{key:`profile-${i}`,staticClass:"d-flex mr-1 mr-lg-3 profile align-items-center",attrs:{"role":"menuitem","aria-label":_vm.$t('global.menu.profile')}},[_c('profile-drop-down',{attrs:{"aria-label":_vm.$t('global.menu.profile'),"menu-item":menuItem.children,"current-path":_vm.currentPath,"analytics":_vm.menu.analytics ? _vm.menu.analytics : null,"company-logo":menuItem.logo},on:{"ctaClicked":function($event){return _vm.handleServiceAccess(
              $event.label,
              $event.id,
              _vm.menu.analytics.id,
              'utility',
              'company-dashboard'
            )},"changeRoute":function($event){return _vm.changeRoute($event.label, $event.id, $event.route)},"changePage":function($event){return _vm.changePage($event.label, $event.id, $event.route)},"logout":function($event){return _vm.$emit('logout')},"trackDropdown":function($event){return _vm.trackMenu(i, menuItem.idMenu)}}})],1):(menuItem.children && menuItem.children.length === 0 && !_vm.isMobile)?_c('b-nav-item',{key:`nav-item-${i}`,staticClass:"d-flex align-items-center nav-link",class:_vm.currentPath.includes(menuItem.route) ? 'active' : '',attrs:{"role":"menuitem","link-classes":"h6 py-0 my-0"},on:{"click":function($event){return _vm.changeRoute(menuItem.label, menuItem.idMenu, menuItem.route)}}},[_vm._v(" "+_vm._s(menuItem.label)+" ")]):(menuItem.type === 'master' && !_vm.isMobile)?_c('b-nav-item-dropdown',{key:`programs-${i}`,staticClass:"d-flex align-items-center h6 py-0 my-0",class:_vm.currentPath.includes('master') || _vm.currentPath.includes('stream') ? 'active' : '',attrs:{"role":"list","text":menuItem.label,"left":""},on:{"shown":function($event){return _vm.trackMenu(menuItem.label, menuItem.idMenu)}}},_vm._l((menuItem.children),function(menuChildren,i){return _c('div',{key:i,attrs:{"role":"listitem"}},[_c('b-dropdown-item',{staticClass:"d-flex align-items-center py-1",class:_vm.serviceActive(menuChildren.code) ? 'active' : '',attrs:{"role":"listitem"},on:{"click":function($event){_vm.handleServiceAccess(
                menuChildren.label,
                menuChildren.idMenu,
                menuChildren.id,
                _vm.getRouteName(menuChildren.route),
                _vm.getRouteName(menuChildren.route)
              )}}},[_vm._v(" "+_vm._s(menuChildren.label)+" ")]),(i < menuItem.children.length - 1)?_c('b-dropdown-divider',{staticClass:"px-4"}):_vm._e()],1)}),0):(menuItem.children && menuItem.children.length > 0 && !_vm.isMobile)?_c('b-nav-item-dropdown',{key:`dropdown-${i}`,staticClass:"d-flex align-items-center h6 py-0 my-0",class:_vm.currentPath === menuItem.route ? 'active' : '',attrs:{"text":menuItem.label,"left":"","role":"list"},on:{"shown":function($event){return _vm.trackMenu(menuItem.label, menuItem.idMenu)}}},_vm._l((menuItem.children),function(menuChildren,i){return _c('b-dropdown-item',{key:i,staticClass:"d-flex align-items-center",class:_vm.currentPath === menuChildren.route ? 'active' : '',attrs:{"role":"listitem"},on:{"click":function($event){return _vm.changeRoute(menuChildren.label, menuChildren.idMenu, menuChildren.route)}}},[_vm._v(" "+_vm._s(menuChildren.label)+" ")])}),1):(menuItem.type !== 'analytics' && menuItem.type !== 'logo' && !_vm.isMobile)?_c('b-nav-item',{key:`analytics-${i}`,staticClass:"d-flex align-items-center nav-link",class:_vm.currentPath === menuItem.route ? 'active' : '',attrs:{"role":"menuitem","link-classes":"h6 py-0 my-0"},on:{"click":function($event){return _vm.changeRoute(menuItem.label, menuItem.idMenu, menuItem.route)}}},[_vm._v(" "+_vm._s(menuItem.label)+" ")]):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }