import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "vee-validate/dist/locale/en.json";
import it from "vee-validate/dist/locale/it.json";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const validationLocales = { en: en.messages, it: it.messages };
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const folder = key.split("/")[1];
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      if (!messages[locale]) {
        messages[locale] = {};
      }

      if (folder === "validation") {
        // Merge vee-validate validation errors with our custom ones
        messages[locale].validation = { ...validationLocales[locale], ...locales(key) };
      } else {
        messages[locale][folder] = locales(key);
      }
    }
  });
  return messages;
}

// Get browser locale and initialize locale with it
const languages = ["en", "it"];
const userLocale = window.navigator.userLanguage || window.navigator.language;
let defaultLocale = process.env.VUE_APP_I18N_LOCALE || "en";

if (userLocale) {
  const shortLocale = userLocale.substring(0, 2);
  if (languages.includes(shortLocale) && defaultLocale !== shortLocale) {
    defaultLocale = shortLocale;
  }
}

export default new VueI18n({
  locale: defaultLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
