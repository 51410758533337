<template>
  <b-sidebar
    id="sidebar-menu"
    left
    shadow
    backdrop
    backdrop-variant="dark"
    role="region"
    :aria-label="$t('global.sideMenu.ariaLabel')"
  >
    <template #header="{ hide }">
      <div class="d-flex justify-content-end w-100">
        <b-button squared variant="link" class="p-0" :aria-label="$t('global.close')" @click="hide">
          <b-icon icon="x" font-scale="2" :aria-label="$t('global.close')" />
        </b-button>
      </div>
    </template>
    <slot />
  </b-sidebar>
</template>

<script>
export default {
  name: "MenuSidebar",
};
</script>

<style scoped lang="scss">
::v-deep .b-sidebar-header {
  background: $color-white;
}

::v-deep #sidebar-menu {
  @media (max-width: 991px) {
    width: 100%;
  }
}

::v-deep .b-sidebar-body {
  background: $color-white;
  .navbar-nav {
    padding: 0 ($spacer * 1.5);

    .nav-item {
      flex-direction: column;
    }

    .nav-link {
      font-weight: bold;
      width: 100%;
      padding: ($spacer * 0.75) 0;
      color: $color-dark;

      &:after {
        right: 10px;
        position: absolute;
        top: 25px;
      }

      &:hover {
        color: $color-blue;
      }
    }

    .dropdown-menu {
      transform: none !important;
      position: relative !important;
      width: 100%;
      border: none;
    }
    li {
      &:not(.active) {
        .dropdown-item {
          color: $color-dark;
          margin: ($spacer * 0.5) 0;
        }
      }

      .dropdown-item {
        font-weight: bold;
        &:hover {
          color: $color-blue;
        }
      }
    }
  }
}
::v-deep .notification,
::v-deep .profile {
  display: none !important;
}

::v-deep .dashboard-button {
  position: absolute;
  bottom: 0;
  right: $spacer;
}
</style>
