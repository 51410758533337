import { mapMutations, mapGetters, mapActions } from "vuex";
import navMixin from "./navMixin";

const mixin = {
  methods: {
    ...mapMutations({
      setIntendedUrl: "user/setIntendedUrl",
    }),

    ...mapActions({
      getMenu: "user/getMenu",
    }),
    async checkService(id, type = "") {
      const props = {};
      props[`${type}Id`] = id;

      try {
        const res = await this.$axios.get(`/api/rest/services/init/${id}?type=${type}`);

        await this.getMenu(true);

        if (res.data.nav === "") {
          if (this.intendedUrl) {
            this.$router.push(this.intendedUrl);
            this.setIntendedUrl(null);
          }
        } else {
          this.$router.push(this.parseNav(res.data.nav));
        }
      } catch (err) {
        console.error(err);
      }
    },
    accessService(id, type, routeName) {
      if (!type) {
        return;
      }

      const props = {};
      props[`${type}Id`] = id;
      const typeName = type[0].toUpperCase() + type.substring(1);
      this.$mixpanel.track(`GoTo${typeName}`, props);

      this.$router.push({ name: routeName, params: { id } });
    },
  },
  computed: {
    ...mapGetters({
      intendedUrl: "user/getIntendedUrl",
    }),
  },
  mixins: [navMixin],
};

export default mixin;
