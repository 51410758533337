import store from "@/store";

const init = () => {
  const { Cookiebot } = window;
  if (!Cookiebot) return;

  if (Cookiebot.consent.statistics) {
    store.commit("user/setStatisticsPermitted", true);
    store.dispatch("user/initTracking");
  }
};

window.addEventListener(
  "CookiebotOnConsentReady",
  () => {
    init();
  },
  false
);

window.addEventListener(
  "CookiebotOnTagsExecuted",
  () => {
    init();
  },
  false
);
