const emptySaveData = {
  current_service: "",
  current_step: 1,
  is_service_activation: 0,
  scopeName: "",
};

// initial state
const initialState = () => ({
  currentScope: {},
  scopes: [],
  saveData: { ...emptySaveData },
  options: {},
  service: {},
  completed: false,
  started: false,
  loading: false,
  toasts: [],
  scopeSaveStates: [],
  wizard: {
    steps: {
      current: 1,
      total: 14,
    },
  },
});

const getters = {
  isCompleted: (state) => state.completed,
  isStarted: (state) => state.started,
  currentScope: (state) => state.currentScope,
  service: (state) => state.service,
  getToasts: (state) => state.toasts,
  hasUnsavedForm: (state) =>
    state.scopeSaveStates.findIndex((scope) => scope.state === false) !== -1,
  getWizardCurrentStep: (state) => state.wizard.steps.current,
  getWizardTotalSteps: (state) => state.wizard.steps.total,
};

const mutations = {
  setCurrentScope(state, scope) {
    state.currentScope = scope;
  },
  setCurrentStep(state, step) {
    state.saveData.current_step = step;
  },
  setCompleted(state, completeState) {
    state.completed = completeState;
  },
  setStarted(state, startState) {
    state.started = startState;
  },
  setIsServiceActivation(state, activationState) {
    state.saveData.is_service_activation = activationState;
  },
  setScopeName(state, name) {
    state.saveData.scopeName = name;
  },
  setScopes(state, scopes) {
    state.scopes = scopes;
  },
  setCurrentService(state, currentService) {
    state.saveData.current_service = currentService;
  },
  setService(state, service) {
    state.service = service;
  },
  setWizardCurrentStep(state, step) {
    state.wizard.steps.current = step;
  },
  setWizardTotalSteps(state, step) {
    state.wizard.steps.total = step;
  },
  setScopeSaveStates(state, payload) {
    if (payload.length > 0) {
      state.scopeSaveStates = payload.map((v) => ({ code: v.scopeData.code, state: true }));
    }
  },
  updateScopeSaveState(state, payload) {
    const index = state.scopeSaveStates.findIndex((scope) => scope.code === payload.identifier);
    if (index !== -1) {
      const newScope = { ...state.scopeSaveStates[index], state: payload.state };
      state.scopeSaveStates.splice(index, 1, newScope);
    }
  },
  resetActivation(state) {
    state.saveData = emptySaveData;
    state.started = false;
    state.completed = false;
  },
  addToast(state, payload) {
    state.toasts.push({
      message: payload.message,
      options: {
        title: payload.title ? payload.title : "Toast",
        variant: payload.variant ? payload.variant : "info",
        autoHideDelay: 3000,
        appendToast: true,
      },
    });
  },
  addErrorToast(state, payload) {
    const message = payload.error;
    state.toasts.push({
      message,
      options: {
        title: "Error",
        variant: "danger",
        autoHideDelay: 3000,
        appendToast: true,
      },
    });
  },
  addValidationErrorToast(state, payload) {
    const messages = Object.values(payload.error).flat().join("; ");
    state.toasts.push({
      message: messages,
      options: {
        title: "Error",
        variant: "danger",
        autoHideDelay: 3000,
        appendToast: true,
      },
    });
  },
};

export default {
  namespaced: true,
  getters,
  state: initialState,
  mutations,
};
