import Vue from "vue";
import Axios from "axios";

const axiosConfig = {
  baseURL: process.env.VUE_APP_BACKEND_URL || "http://localhost/api",
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

const axios = Axios.create(axiosConfig);

let refreshed = false;

axios.interceptors.response.use(
  (response) =>
    // Do something with response data
    response,
  async (error) => {
    const originalConfig = error.config;
    if (!error.response || !error.response.status) return Promise.reject(error);
    switch (error.response.status) {
      case 401:
        if (!refreshed) {
          try {
            refreshed = true;
            const res = await axios.get("/api/rest/csrf-cookie");
            axios.defaults.headers.common["X-XSRF-TOKEN"] = res.data;
            await axios.post("/api/rest/refresh-token");
            return axios(originalConfig);
          } catch (err) {
            refreshed = false;
            window.location.replace(
              `${process.env.VUE_APP_ACCOUNTS_URL}?intendedUrl=${window.location.href}`
            );
          }
        }
        break;
      case 403:
        window.location.replace(`${process.env.VUE_APP_MYACCOUNT_URL}/forbidden`);
        break;
      case 404:
      case 500:
        window.dispatchEvent(new CustomEvent("servererror", { detail: error.response.status }));
        break;
      default:
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;

export default axios;
