<template>
  <div class="h-100 d-flex flex-column content-container overflow-hidden">
    <div
      v-if="loading"
      class="d-flex flex-column align-items-center justify-content-center w-100 my-auto px-5"
    >
      <div
        class="spinner-border text-secondary mb-4"
        style="width: 3rem; height: 3rem"
        role="status"
      />
      <markdown :source="$t('home.loading')" tabindex="0" class="text-center mt-4" />
    </div>

    <template v-else>
      <menu-container v-if="$route.meta.hideHeader !== true" />
      <div class="w-100 header-padding-top d-flex flex-column content-container overflow-hidden">
        <router-view></router-view>
      </div>
      <site-footer v-if="$route.meta.hideFooter !== true" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Markdown from "@/atoms/Markdown.vue";
import SiteFooter from "@/templates/SiteFooter.vue";
import MenuContainer from "@/organisms/menu/MenuContainer.vue";

export default {
  name: "App",
  components: { Markdown, MenuContainer, SiteFooter },
  data() {
    return {
      unwatchProfile: null,
    };
  },
  computed: {
    ...mapGetters({
      loading: "user/getLoadingStatus",
    }),
  },
  mounted() {
    this.unwatchProfile = this.toastWatcher("profile/getToasts");
  },
  updated() {
    this.addAltAttribute();
  },
  methods: {
    makeToast(message, options) {
      this.$bvToast.toast(message, options);
    },
    toastWatcher(getter) {
      return this.$store.watch(
        (state, getters) => getters[getter],
        (newValue) => {
          const lastToast = newValue.slice(-1)[0];
          this.makeToast(lastToast.message, lastToast.options);
        }
      );
    },
    addAltAttribute() {
      const cookieImg = document.getElementById("CookiebotSessionPixel");
      cookieImg.alt = "";
    },
  },
  beforeDestroy() {
    this.unwatchProfile();
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";

.content-container {
  flex: 1 0 auto;
}
</style>
