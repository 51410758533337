/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Vue from "vue";
import {
  extend,
  configure,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { regex } from "vee-validate/dist/rules";
import i18n from "../i18n";

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    if (i18n.te(`fields.${field}`)) {
      values._field_ = i18n.t(`fields.${field}`);
    }

    return i18n.t(`validation.${values._rule_}`, values);
  },
});

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

setInteractionMode("eager");

// Install password rule and message.
extend("password", {
  ...regex,
  validate: (value) => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value),
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
